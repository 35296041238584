<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="所在单位">
          <el-select v-model="searchForm.unitName" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" size="small">
            <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.unitName">
              <span style="float: left">{{ item.unitName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在区域">
          <el-cascader :disabled="isLoad" v-model="areaCodes" size="small" placeholder="请选择" :options="areaList" :props="{ checkStrictly: true }" clearable></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="roleList" stripe style="width: 100%" height="100%">
        <el-table-column prop="areaName" label="所属区域" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="unitName" label="所属单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="username" label="用户名" show-overflow-tooltip>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,
      total: 0,
      roleList: [],
      areaList: [],
      searchForm: {},
      unitOptions: [],
      areaCodes: [],
      isLoad: false
    }
  },
  created () {
    this.getAreaList()
    this.initTable()
    this.roleFormCopy = JSON.stringify(this.roleForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      if (this.searchForm.unitName) {
        data.unitName = this.searchForm.unitName
      }
      if (this.areaCodes.length > 0) {
        data.areaCode = this.areaCodes[this.areaCodes.length - 1]
      }
      const { data: result } = await this.$axios.get('/user/listUnitAccount', { params: data })
      if (result.code === 200) {
        this.roleList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    changeKey (array) {
      array.forEach((item, index) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem, index2) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2, index) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog {
  margin: 0;
  padding: 10px;
  ::v-deep  .el-dialog__header {
    display: none !important;
  }
}
.demo-table-expand {
  font-size: 0;
  .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
    ::v-deep  label {
      color: #99a9bf !important;
    }
  }
}
::v-deep  .el-table__expanded-cell {
  padding: 0 50px;
}
</style>
